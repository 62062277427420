import { createSlice } from "@reduxjs/toolkit";
import { IPriceSummary, priceSummary } from "../action";

export interface IPriceSummarySlice {
  plan: IPriceSummary | null;
  message: string;
  loading: boolean;
  error: string;
}

const initialState: IPriceSummarySlice = {
  plan: null,
  loading: false,
  error: "",
  message: "",
};

export const priceSummarySlice = createSlice({
  name: "priceSummary",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    //price-summary
    builder.addCase(priceSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(priceSummary.fulfilled, (state, action) => {
      state.loading = false;
      state.plan = action.payload;
    });
    builder.addCase(priceSummary.rejected, (state, action) => {
      state.loading = false;
      state.plan = initialState.plan;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const { reset } = priceSummarySlice.actions;
export default priceSummarySlice.reducer;
