export const ROUTERS = {
  home: "/",
  freeTrial: "/free-trial",
  bookTour: "/book-tour",
  network: "/network",
  pricingPlanBanner: "/pricing-plan",
  oneClub: "/join-one-club",
  allClubs: "/clubs",
  findLocalClub: "/find-local-club",
  memberDetails: "/member-details/:id",
  addMember: "/add-member/:id",
  addPerks: "/add-perk/:id",
  checkout: "/checkout/:id",
  singleClub: "/one-club",
};
