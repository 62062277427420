import cs from "classnames";
import React from "react";
import classes from "./loader.module.scss";

interface Props {
  showFullPage?: boolean;
}

const Loader: React.FC<Props> = ({ showFullPage = true }) => {
  return (
    <div
      className={cs(classes.loaderWrapper, {
        [classes.fullPageWrapper]: showFullPage,
      })}
    >
      <div className={classes.loader}></div>
    </div>
  );
};

export default Loader;
