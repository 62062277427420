import { createAsyncThunk } from "@reduxjs/toolkit";

interface IPayload<T> {
  data: T;
  cb?: () => void;
}

export interface IMemberDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export const memberDetails = createAsyncThunk(
  "member/register",
  async (payload: IPayload<IMemberDetails>) => {
    const { data, cb } = payload;
    try {
      if (cb) cb();
      return data;
    } catch (err) {
      throw new Error("Something went wrong");
    }
  },
);

export const freeMemberDetails = createAsyncThunk(
  "freeMember/register",
  async (payload: IPayload<IMemberDetails>) => {
    const { data, cb } = payload;
    try {
      if (cb) cb();
      return data;
    } catch (err) {
      throw new Error("Something went wrong");
    }
  },
);
