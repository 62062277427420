import { createSlice } from "@reduxjs/toolkit";
import { IMemberDetails, freeMemberDetails, memberDetails } from "../action";

export interface IMemberDetailsSlice {
  member: IMemberDetails | null;
  freeTrailMember: IMemberDetails | null;
  message: string;
  loading: boolean;
  error: string;
}

const initialState: IMemberDetailsSlice = {
  member: null,
  freeTrailMember: null,
  loading: false,
  error: "",
  message: "",
};

export const memberDetailsSlice = createSlice({
  name: "memberDetails",
  initialState,
  reducers: {
    resetMemberData: () => initialState,
  },
  extraReducers: (builder) => {
    //member-Details
    builder.addCase(memberDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(memberDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.member = action.payload;
    });
    builder.addCase(memberDetails.rejected, (state, action) => {
      state.loading = false;
      state.member = null;
      state.error = action.error.message || "Something went wrong";
    });
    //free-member-Details
    builder.addCase(freeMemberDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(freeMemberDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.freeTrailMember = action.payload;
    });
    builder.addCase(freeMemberDetails.rejected, (state, action) => {
      state.loading = false;
      state.member = null;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const { resetMemberData } = memberDetailsSlice.actions;
export default memberDetailsSlice.reducer;
