import { lazy } from "react";
import { Route, Routes } from "react-router";

import classes from "./App.module.scss";
import ScrollTop from "./components/ScrollTop/ScrollTop";
import { ROUTERS } from "./routes";
const Home = lazy(() => import("./pages/Home"));
const Network = lazy(() => import("./pages/Network"));
const FreeTrial = lazy(() => import("./pages/FreeTrial"));
const BookTour = lazy(() => import("./pages/BookTour"));
const PricingPlanBanner = lazy(() => import("./pages/PricingPlanBanner"));
const OneClub = lazy(() => import("./pages/OneClub"));
const AllClubs = lazy(() => import("./pages/AllClubs"));
const FindLocalClub = lazy(() => import("./pages/FindLocalClub"));
const MemberDetails = lazy(() => import("./pages/MemberDetails"));
const AddMember = lazy(() => import("./pages/AddMember"));
const AddPerks = lazy(() => import("./pages/AddPerks"));
const Checkout = lazy(() => import("./pages/Checkout"));
const SingleClub = lazy(() => import("./pages/SingleClub"));

const App = () => {
  return (
    <div className={classes.app}>
      <ScrollTop />
      <Routes>
        <Route path={ROUTERS.home} element={<Home />} />
        <Route path={ROUTERS.freeTrial} element={<FreeTrial />} />
        <Route path={ROUTERS.network} element={<Network />} />
        <Route path={ROUTERS.bookTour} element={<BookTour />} />
        <Route
          path={ROUTERS.pricingPlanBanner}
          element={<PricingPlanBanner />}
        />
        <Route path={ROUTERS.oneClub} element={<OneClub />} />
        <Route path={ROUTERS.allClubs} element={<AllClubs />} />
        <Route path={ROUTERS.findLocalClub} element={<FindLocalClub />} />
        <Route path={ROUTERS.memberDetails} element={<MemberDetails />} />
        <Route path={ROUTERS.addMember} element={<AddMember />} />
        <Route path={ROUTERS.addMember} element={<AddMember />} />
        <Route path={ROUTERS.addPerks} element={<AddPerks />} />
        <Route path={ROUTERS.checkout} element={<Checkout />} />
        <Route path={ROUTERS.singleClub} element={<SingleClub />} />
      </Routes>
    </div>
  );
};

export default App;
