import { createAsyncThunk } from "@reduxjs/toolkit";

interface IPayload<T> {
  data: T;
  cb?: () => void;
}
export enum PlanType {
  NETWORK = "network",
  ALL = "all",
  ONE = "one",
}
export enum TermType {
  ANNUAL = "annual",
  MONTHLY = "monthly",
}

export interface IPerks {
  id: number;
  name: string;
  amount: number;
}

interface IPlan {
  id: number;
  term: TermType;
  planName: PlanType;
  actualAmount: number;
  enrollmentFees: number;
  discountAmount: number;
  perks?: IPerks;
  price?: number;
}

interface IExtraMembers {
  adult: number;
  child: number;
}
export interface IPriceSummary {
  price?: IPlan;
  extraMembers?: IExtraMembers;
}

export const priceSummary = createAsyncThunk(
  "price/Summary",
  async (payload: IPayload<IPriceSummary>) => {
    const { data, cb } = payload;
    try {
      if (cb) cb();
      return data;
    } catch (err) {
      throw new Error("Something went wrong");
    }
  },
);
