import { useEffect } from "react";
import { useLocation } from "react-router";
const excludeScrollPath = ["/clubs"];
const ScrollTop: React.FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (!excludeScrollPath.includes(pathname)) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [pathname]);
  return <></>;
};
export default ScrollTop;
